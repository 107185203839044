<template>
  <div class="daily-summary">
    <h1
      v-if="title"
      class="daily-summary__title"
    >
      {{ title }}
    </h1>
    <div
      v-if="diagnosis.nutritionKey && layout !== 'compact'"
      class="diagnosis-summary"
    >
      <span class="diagnosis-summary__consumed diagnosis-summary__statistic">
        <span class="diagnosis-summary__value">
          {{ diagnosisValue }} {{ getNutritionUnit(diagnosis.nutritionKey) }}
        </span>
        <span class="diagnosis-summary__label">{{ $t('consumed') }}</span>
      </span>
      <van-circle
        class="daily-diagnosis"
        :color="diagnosisPercentageColor"
        :value="diagnosisPercentage"
        :stroke-width="70"
        :size="120"
      >
        <strong class="daily-diagnosis__title">
          {{ getAbbreviation(diagnosis.nutritionKey) }}
        </strong>
        <span class="daily-diagnosis__statistic">
          {{ $t('summary.consumed', [diagnosisPercentage]) }}
        </span>
        <span class="daily-diagnosis__statistic">
          {{ diagnosisValue }} / {{ diagnosisLimit }} {{ getNutritionUnit(diagnosis.nutritionKey) }}
        </span>
      </van-circle>
      <span class="diagnosis-summary__remaining diagnosis-summary__statistic">
        <span class="diagnosis-summary__value">
          {{ diagnosisRemaining }} {{ getNutritionUnit(diagnosis.nutritionKey) }}
        </span>
        <span class="diagnosis-summary__label">{{ $t('remaining') }}</span>
      </span>
    </div>
    <statistic-grid :class-props="classProps">
      <statistic
        :title="$t('calories')"
        :unit="getNutritionUnit('calories')"
        :value="nutritionSums.calories"
        :max="nutritionLimits.calories"
        class="diagnosis-summary__calories"
      />
      <statistic
        v-if="layout === 'compact'"
        :title="$t(`nutritions.${diagnosis.nutritionKey}`)"
        :unit="getNutritionUnit(diagnosis.nutritionKey)"
        :value="diagnosisValue"
        :max="diagnosisLimit"
        class="diagnosis-summary__protein"
      />
      <statistic
        v-for="nutritionKey in nutritionKeys"
        :key="nutritionKey"
        :title="$t(`nutritions.${nutritionKey}`)"
        :unit="getNutritionUnit(nutritionKey)"
        :value="nutritionSums[nutritionKey] || 0"
        :max="nutritionLimits[nutritionKey]"
        class="diagnosis-summary__protein"
      />
    </statistic-grid>
  </div>
</template>

<script>
import { getNutritionUnit, getAbbreviation } from '@/helper';
import Statistic from '../Statistic';
import StatisticGrid from '../StatisticGrid';

export default {
  components: {
    Statistic,
    StatisticGrid,
  },
  props: {
    title: { type: String, required: true },
    nutritionSums: { type: Object, required: true },
    nutritionLimits: { type: Object, required: true },
    diagnosis: { type: Object, required: true },
    layout: { type: String, default: null },
  },
  data() {
    return {
      useOptionalKeys: false,
      nutritionKeys: [],
      classProps: '',
    };
  },
  computed: {
    diagnosisValue() {
      return parseFloat(this.nutritionSums[this.diagnosis.nutritionKey] || 0);
    },
    diagnosisLimit() {
      return parseFloat(this.nutritionLimits[this.diagnosis.nutritionKey]);
    },
    diagnosisPercentage() {
      return Math.round((this.diagnosisValue / this.diagnosisLimit) * 100);
    },
    diagnosisRemaining() {
      return Math.max(this.diagnosisLimit - this.diagnosisValue, 0);
    },
    diagnosisPercentageColor() {
      if (this.diagnosisPercentage > 90 && this.diagnosisPercentage < 100) {
        return 'orange';
      }
      if (this.diagnosisPercentage >= 100) {
        return 'red';
      }
      return '#643fab';
    },
  },
  created() {
    const optionalNutritionValues = this.$store.getters['user/useOptionalNutritionValues'];

    this.useOptionalKeys = optionalNutritionValues;
    this.nutritionKeys = optionalNutritionValues ? this.diagnosis.optionalNutritionKeys : [];

    const isNutritionKeysOdd = this.nutritionKeys.length % 2 !== 0 ? ' is-odd' : '';
    const shouldMoveCaloriesToEnd = ['GA 1'].includes(this.diagnosis.name) ? ' is-calories-end' : '';

    this.classProps = (isNutritionKeysOdd + shouldMoveCaloriesToEnd).trim();
  },
  methods: {
    getNutritionUnit,
    getAbbreviation,
  },
};
</script>

<style lang="scss">
@use '~@/styles/config' as config;

.daily-summary {
  padding: config.$spacing;

  h1 {
    margin-top: 0;
    margin-left: 0;
  }
}

.daily-summary__title {
  margin-bottom: 1.523em;
  font-size: config.$font-size-lg;
  font-weight: bold;
  line-height: 1.19;
  @media(min-width: 1024px) {
    font-size: config.$font-size-2lg;
  }
}

.daily-diagnosis {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: config.$spacing-sm;
  text-align: center;
}

.daily-diagnosis__title {
  margin-bottom: 0.4375em;
}

.daily-diagnosis__statistic {
  font-size: 0.6875em;
  font-weight: 300;
}

.diagnosis-summary {
  display: flex;
  justify-content: center;
  grid-gap: config.$spacing;
}

.diagnosis-summary__statistic {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 2.125rem;
  text-align: center;
}

.diagnosis-summary__value {
  margin-bottom: 0.4375em;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.33;
}

.diagnosis-summary__label {
  font-size: 0.6875em;
  font-weight: 300;
}

.diagnosis-summary__calories .van-progress__portion {
  background-color: config.$color-mid;
}

.diagnosis-summary__protein .van-progress__portion {
  background-color: config.$brand-primary;
}
</style>
