<template>
  <div>
    <div
      v-if="statistics && statistics.length > 0"
      class="statistic-grid"
      :class="classProps"
    >
      <statistic
        v-for="statistic in statistics"
        :key="statistic.label"
        v-bind="statistic"
      />
    </div>
    <div
      v-else
      class="statistic-grid"
      :class="classProps"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import Statistic from '../Statistic';

export default {
  components: {
    Statistic,
  },
  props: {
    statistics: { type: Array, default: () => [] },
    classProps: { type: String, default: '' },
  },
};
</script>

<style lang="scss">
@use '~@/styles/config' as config;

.statistic-grid {
  column-gap: config.$spacing-lg;
  display: grid;
  grid-template-columns: 1fr 1fr;

  &.is-odd:not(.is-calories-end):last-child {
    grid-column: 1 / span 2;
  }

  &.is-odd.is-calories-end .diagnosis-summary__calories {
    grid-column: 1 / span 2;
    order: 99;
  }
}
</style>
