<template>
  <div class="statistic">
    <p class="statistic__title">
      {{ title }}
    </p>
    <van-progress
      class="statistic__progress"
      :show-pivot="false"
      :color="progressColor"
      :percentage="progressPercentage"
    />
    <span class="statistic__value">{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    unit: { type: String, required: true },
    value: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
  },
  computed: {
    label() {
      return `${this.value.toLocaleString('de-DE')} /
        ${this.max.toLocaleString('de-DE')}
        ${this.unit}`;
    },
    progressPercentage() {
      // Sometimes the value returns from Math.min() will return in NaN which is
      // a falsy value, so we should just return 0 to prevent a JS exception.
      return Math.min((this.value / this.max) * 100, 100) || 0;
    },
    progressColor() {
      if (this.progressPercentage >= 90 && this.progressPercentage < 100) {
        return 'orange';
      }
      if (this.progressPercentage >= 100) {
        return 'red';
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
@use '~@/styles/config' as config;

.statistic {
  text-align: center;
}

.statistic__title {
  margin-bottom: 0.5em;
  font-size: config.$font-size-xs;
  font-weight: 600;
  font-weight: bold;
  text-align: center;
  @media(min-width: 1024px) {
    font-size: config.$font-size;
  }
}

.statistic__progress {
  margin-bottom: config.$spacing-xxs;
}

.statistic__value {
  font-size: config.$font-size-xs;
  font-weight: 300;
  @media(min-width: 1024px) {
    font-size: config.$font-size;
  }
}
</style>
