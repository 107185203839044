<template>
  <div class="food">
    <van-nav-bar
      :title="mealTitle || title"
      fixed
      placeholder
    >
      <template #left>
        <van-icon
          :name="$icon('back')"
          size="16"
          color="#000"
          is-link
          @click="$router.back()"
        />
      </template>
    </van-nav-bar>
    <hero
      ref="hero"
      :title="mealTitle || title"
      :image="image"
      :hero-bg-icon="heroBgIcon"
      :is-favourite="isFavourite"
      :is-editable="true"
      @mark-favourite="markFavourite"
      @custom-title="customTitle"
    />
    <daily-summary
      title=""
      :date="date"
      :nutrition-sums="nutritionsData"
      :nutrition-limits="nutritionLimits"
      :diagnosis="diagnosis"
      layout="compact"
    />
    <div class="food-tabs">
      <div class="container">
        <div
          class="food-list__heading"
        >
          <h3 class="food-list__title">
            {{ $t('food.add') }}
          </h3>
          <van-button
            icon="plus"
            round
            type="primary"
            class="food-list__add"
            :to="{
              name: 'FoodList',
              params: {
                mealType,
                action: 'addFoodToMeal',
                actionType: 'meal',
                id: mealId
              }
            }"
          />
        </div>
      </div>
      <van-cell-group
        class="food-list__items"
        :border="false"
      >
        <template v-if="items">
          <van-swipe-cell
            v-for="item in items"
            :key="item.id"
            :before-close="beforeClose"
          >
            <food-item
              v-bind="item"
              :diagnosis="diagnosis"
              :nutritions="item.nutritionsCalculated || item.nutritions"
              food-icon="meal"
            >
              <van-icon
                :name="$icon('arrow-list')"
                size="10"
              />
            </food-item>
            <template
              #right
            >
              <van-button
                square
                type="danger"
                :text="$t('clear')"
                @click="removeFood(item.id)"
              />
            </template>
          </van-swipe-cell>
        </template>
        <slot v-else-if="$slots.default" />
        <p
          v-else
          class="food-list__empty"
        >
          {{ $t('foodList.empty') }}
        </p>
      </van-cell-group>
    </div>
    <div class="container">
      <data-table
        :data="productData"
        :title="nutritionalValuesTitle"
        class="food__data"
      />
    </div>
    <div class="branding__container">
      <branding />
    </div>
    <van-form
      validate-first
      class="food-form"
      @submit="saveMeal()"
    >
      <van-collapse
        v-model="activeName"
        accordion
      >
        <van-collapse-item name="1">
          <template #title>
            <span>{{ $t('food.mealType') }}</span>
            <span>{{ $i18n.t(`food.mealTypes.${selectedMealType}`) }}</span>
          </template>
          <van-picker
            show-toolbar
            :columns="mealTypeColumns"
            @confirm="selectMealType"
            @cancel="activeName = null"
          />
        </van-collapse-item>
        <van-collapse-item name="2">
          <template #title>
            <span>{{ $t('date') }}</span>
            <span>{{ dateLabel }}</span>
          </template>
          <calendar
            v-model="date"
            :date-selected="date"
            @date="updateDate"
          />
        </van-collapse-item>
      </van-collapse>
      <van-button
        native-type="submit"
        type="primary"
        block
        round
        :disabled="!title"
      >
        {{ $props.id ? $t('meal.update') : $t('meal.add') }}
      </van-button>
    </van-form>
  </div>
</template>

<script>
import { Notify } from 'vant';
import { auth, analytics, FieldValue } from '@/firebase';
import { formatRelative, isSameDay } from 'date-fns';

import Meal from '@/models/meal';
import Dayplan from '@/models/dayplan';
import Food from '@/models/food';
import Diagnosis from '@/models/diagnosis';
import Calendar from '@/components/Calendar';
import FoodItem from '@/components/FoodItem';
import DataTable from '@/components/DataTable';
import Hero from '@/components/Hero';
import DailySummary from '@/components/DailySummary';
import Branding from '@/components/Branding';

import { dateLocale, getNutritionUnit } from '@/helper';

export default {
  components: {
    Calendar,
    DataTable,
    Hero,
    DailySummary,
    FoodItem,
    Branding,
  },
  props: {
    mealType: { type: String, default: 'breakfast' },
    id: { type: String, default: null },
    foods: { type: Array, default: null },
    mealTitle: { type: String, default: null },
    containsMeal: { type: Boolean, default: false },
  },
  data() {
    return {
      items: this.$store.getters['meal/foodItems'] ?? [],
      nutritionalValuesTitle: `${this.$i18n.t('food.nutritionalValues')}`,
      nutritionLimits: {},
      userId: auth.currentUser.uid,
      diagnosis: new Diagnosis(this.$store.getters['user/diagnosisName']),
      activeName: null,
      date: this.$store.getters['dayplan/selectedDate'],
      foodDisclaimer: null,
      title: this.$store.getters['meal/mealTitle'] || this.$i18n.t('meal.title'),
      heroBgIcon: '/img/meal/fallback.png',
      image: '',
      nutritions: [],
      nutritionsData: {},
      isFavourite: false,
      isEditable: false,
      mealId: '',
      mealTypeColumns: [
        {
          text: this.$i18n.t('food.mealTypes.breakfast'),
          value: 'breakfast',
        },
        {
          text: this.$i18n.t('food.mealTypes.lunch'),
          value: 'lunch',
        },
        {
          text: this.$i18n.t('food.mealTypes.dinner'),
          value: 'dinner',
        },
        {
          text: this.$i18n.t('food.mealTypes.snack'),
          value: 'snack',
          selected: true,
        },
      ],
      selectedMealType: 'breakfast',
      mealDetails: {},
    };
  },
  computed: {
    dateLabel() {
      return formatRelative(this.date, Date.now(), {
        locale: dateLocale,
      });
    },
    productData() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.nutritionsData = {};
      const nutrients = {};
      this.items.forEach((item) => {
        Object.entries(item.nutritionsCalculated || item.nutritions).map((entry) => {
          const [label, value] = entry;
          if (this.nutritionsData[label]) {
            this.nutritionsData[label] += value;
            nutrients[label] += value;
          } else {
            this.nutritionsData[label] = value;
            nutrients[label] = value;
          }
          return this.nutritionsData;
        });
      });
      const relevantNutrients = Dayplan.getRelevantNutrients(this.nutritionsData);
      const nutritions = Object.entries(relevantNutrients).map((entry) => {
        const [label, value] = entry;
        return {
          label: this.$i18n.t(`nutritions.${label}`),
          value: typeof value === 'number'
            ? `${value.toLocaleString('de-DE', { maximumFractionDigits: 1 })} ${getNutritionUnit(label)}`
            : value,
        };
      });
      return nutritions.sort((a, b) => {
        const diagnosisLabel = this.diagnosis.getLabel(false);
        // Show relevant diagnosis nutrition value first.
        if (a.label === diagnosisLabel) {
          return -1;
        }
        if (b.label === diagnosisLabel) {
          return 1;
        }
        // Sort unspecified values last.
        if (a.value.trim() === 'k.A.') {
          return 1;
        }
        if (b.value.trim() === 'k.A.') {
          return -1;
        }
        return false;
      });
    },
  },
  mounted() {
    this.selectedMealType = this.mealType;
    this.nutritionLimits = this.$store.getters['user/nutritionLimits'];
  },
  created() {
    this.diagnosis = new Diagnosis(this.$store.getters['user/diagnosisName']);
    this.mealId = this.$store.getters['meal/mealId'];
    if (this.$props.id) {
      this.getMealDetails(this.$props.id);
    }
  },
  methods: {
    beforeClose({ position, instance }) {
      if (position === 'outside') {
        instance.close();
      }
    },
    removeFood(itemId) {
      let item;
      this.items.forEach((foodItem) => {
        if (foodItem.id === itemId) {
          item = foodItem;
        }
      });
      this.$store.commit('meal/removeFoodItem', itemId);
      if (item) {
        analytics.logEvent('meal_remove_food', {
          item_id: item.foodId ?? item.id,
          item_name: item.title,
          item_category: item.category,
          item_list_id: this.selectedMealType,
        });
      }
    },
    customTitle(value) {
      this.title = value;
    },
    markFavourite(value) {
      this.isFavourite = value;
      if (this.id) {
        Food.markFavourite(this.id, value);
        analytics.logEvent(value ? 'favorite_add_meal' : 'favorite_remove_meal', {
          item_id: this.id,
          item_name: this.title,
          item_category: 'meal',
          item_list_id: this.mealType,
        });
      }
    },
    addMealToDayplan(mealRef, isNew = false) {
      Dayplan.addMeal({
        id: mealRef.id,
        mealTypes: FieldValue.arrayUnion(this.selectedMealType),
      }).then(() => {
        if (isNew) {
          // Remove items from dayplan.
          this.items.forEach((item) => {
            Dayplan.removeFood(item.id);
          });
        }
        // add foods in 'breakdown' for fetching meal details
        this.$store.commit('meal/setFoodItems', this.items);
        Notify({
          type: 'success',
          message: isNew ? this.$i18n.t('meal.created') : this.$i18n.t('meal.updated'),
        });
        analytics.logEvent(isNew ? 'day_add_meal' : 'day_edit_meal', {
          item_id: mealRef.id,
          item_name: this.title,
          item_category: 'meal',
          item_list_id: this.selectedMealType,
        });
        this.$router.push({ name: 'dashboard' });
      });
    },
    saveMeal() {
      const mealId = this.$store.getters['meal/mealId'];
      if (mealId) {
        const hasUnsavedChanges = this.$store.getters['meal/hasUnsavedChanges'];
        if (hasUnsavedChanges) {
          this.createMeal(mealId);
          return Dayplan.removeMeal(mealId);
        }

        const mealRef = Meal.getMeal(mealId);
        if (this.$refs.hero.customTitle !== this.$store.getters['meal/mealTitle']) {
          this.updateTitle();
        }
        return this.addMealToDayplan(mealRef);
      }
      return this.createMeal();
    },
    createMeal(originalMealId) {
      Meal.create({
        title: this.$refs.hero.customTitle,
        isFavourite: this.isFavourite,
        nutritions: this.nutritionsData,
        foods: this.items,
      }).then((docRef) => {
        analytics.logEvent(originalMealId ? 'meal_update' : 'meal_create', {
          item_id: docRef.id,
          item_name: this.$refs.hero.customTitle,
          item_category: 'meal',
          item_list_id: this.selectedMealType,
        });
        this.addMealToDayplan(docRef, !originalMealId);
        // eslint-disable-next-line
      }).catch((error) => {
        // console.log(error);
        Notify({
          type: 'danger',
          message: this.$i18n.t('food.fail'),
        });
      });
    },
    updateTitle() {
      Meal.update({
        title: this.$refs.hero.customTitle,
        mealId: this.$store.getters['meal/mealId'],
      }).then(() => {
        Notify({
          type: 'success',
          message: this.$i18n.t('meal.updated'),
        });
      });
    },
    selectMealType(option) {
      this.selectedMealType = option.value;
      this.activeName = null;
    },
    updateDate(value) {
      this.date = value;
      this.activeName = null;
      this.showDatePicker = false;
      this.$store.commit('dayplan/selectDate', this.date);
    },
    getMealDetails(id) {
      const mealRef = Meal.getMeal(id).get();
      mealRef.then((meal) => {
        this.mealDetails = meal.data();
        this.$store.commit('meal/setMealTitle', meal.data().title);
        this.$store.commit('meal/setMealId', id);
      });
    },
    isSameDay,
  },
};
</script>

<style lang="scss">
@use '~@/styles/config' as config;
@use '~@/styles/icons' as icons;

.food-tabs {
  margin-top: config.$spacing-xl;
  margin-bottom: config.$spacing-xl;
}

.food-list__items {
  margin-top: config.$spacing;
}

.food-list__heading {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: config.$spacing-xl 0 config.$spacing 0;
}

.food-list__title {
  margin: 0;
  font-size: config.$font-size-md;
  font-weight: bold;
  @media(min-width: 1024px) {
    font-size: 22px;
  }
}

.food-list__add {
  width: 1.625rem;
  height: 1.625rem;
  padding: 0.125rem;

  .van-button__icon {
    line-height: 1;
    font-size: 1rem;
    color: #fff;
  }
}

.food__link {
  border: none;
  margin-bottom: config.$spacing-xxl;

  .van-icon {
    margin-right: 0.5em;
  }
}

.food__data {
  margin-bottom: config.$spacing;
}

.food__notice {
  margin-top: config.$spacing-xxl;
}

.food-form {
  position: sticky;
  z-index: 20;
  bottom: 0;
  padding: config.$spacing;
  margin-top: config.$spacing-xs;
  box-shadow: 0 -2px 10px 0 rgba(121, 121, 121, 0.25);
  background-color: config.$color-lighter;

  .van-collapse {
    margin-bottom: 0.625rem;

    &:after {
      display: none;
    }
  }

  .van-collapse-item + .van-collapse-item {
    border-top: 1px solid #b9b9b9;
  }

  .van-collapse-item__content {
    padding: 0;
  }

  .van-cell__title {
    display: flex;
    justify-content: space-between;
  }

  .van-cell__right-icon {
    display: none;
  }

  .van-cell {
    padding: 0.375rem 0.75rem;
    background-color: transparent;
  }
}

.food-form__input {
  border: solid 1px #e7e7e7;
  border-radius: 5px;
  margin-bottom: config.$spacing-xs;
  background-color: #fff;
  color: #242526;
}
</style>
